import type { Asset } from '@schibsted-svp/svp-api-types';
import { ConsentManager } from '@vgtv/consent-manager';

import {
  getDeviceType,
  isAndroid,
  isHermesApp,
  isIOS,
} from '../../../utils/device';
import {
  getAssetAspectRatio,
  getAssetEmbeddedTags,
  getAssetMetadata,
} from '../../../utils/player/asset';
import { getTcfConsentKeywords } from '../getTcfConsentKeywords';
import { getAdConsentKeywords } from '../getAdPermission';
import { prepareXandrKeywords } from '../prepareKeywords';
import type { XandrKeywords } from '../types';

import { formatTagValue } from './formatTagValue';
import { getAspectRatioCompound } from './getAspectRatioCompound';
import { getGlimrTags } from './glimr';

function getAppTypeKeyword(): Record<string, string> {
  if (!isHermesApp || (!isIOS && !isAndroid)) {
    return {};
  }
  const deviceType = getDeviceType();

  return {
    'no-sno-apptype': `${isIOS ? 'ios' : 'android'}_${deviceType}_hybrid`,
  } as const;
}

export function getClientKeywords(
  dynamicAR: boolean,
  assetAR: number,
  playerAspectRatio?: number
) {
  const keywords: Record<string, string | boolean> = {};
  if (assetAR) {
    const { width, height } =
      getAspectRatioCompound(assetAR, [
        [1, 1],
        [16, 9],
        [4, 3],
      ]) ?? {};
    if (width && height) {
      keywords['aa-sch-instream_aspectratio'] = `${width}-${height}`;
    }
  }

  if (dynamicAR) {
    keywords['aa-sch-instream_dynamicenabled'] = dynamicAR;
    keywords['aa-sch-instream_player_aspectratio'] = 'responsive';
  } else if (playerAspectRatio || assetAR) {
    // Fall back to asset aspect ratio if player container's height is 0
    const { width, height } =
      getAspectRatioCompound(playerAspectRatio || assetAR, [
        [1, 1],
        [16, 9],
      ]) ?? {};
    if (width && height) {
      keywords['aa-sch-instream_player_aspectratio'] = `${width}-${height}`;
    }
  }

  return keywords;
}

export function getAssetKeywords(
  asset: Asset,
  dynamicAR = false,
  playerAspectRatio?: number
) {
  const categoryTitle = asset.category.title;
  const { contentType } = getAssetMetadata(asset);
  const keywords: Record<
    string,
    string | (string | undefined)[] | boolean | undefined
  > = {
    'aa-sch-content_type': [
      `${formatTagValue(categoryTitle)}_${contentType}`,
      formatTagValue(contentType),
    ],
    'no-sno-news-sponsors': getAssetEmbeddedTags(asset)
      .map(({ tag }) => tag)
      .map(formatTagValue),
  };

  return {
    ...keywords,
    ...getClientKeywords(
      dynamicAR,
      getAssetAspectRatio(asset),
      playerAspectRatio
    ),
  };
}

function getGlimrKeywords() {
  const geoTags = getGlimrTags();

  return geoTags
    ? {
        'no-sno-geo': geoTags,
        'no-sno-weather': geoTags,
      }
    : {};
}

function addNonSkipKeywords(keywords: Partial<XandrKeywords>) {
  if (
    keywords['aa-sch-page_type'] === 'article' &&
    keywords['aa-sch-instream_player_aspectratio'] === '9-16'
  ) {
    return {
      ...keywords,
      'aa-sch-instream_playercontext': 'stories',
    };
  }

  return keywords;
}

export const getGlobalKeywords = async (
  keywords: Partial<
    Record<
      | 'countryCode'
      | 'supplyType'
      | 'publisherName'
      | 'pageType'
      | 'publisherGroup'
      | 'newsSection',
      string | string[]
    >
  >,
  asset: Asset,
  consentManager?: ConsentManager,
  dynamicAR: boolean = false,
  playerAspectRatio?: number
) => {
  return prepareXandrKeywords(
    addNonSkipKeywords(
      Object.assign(
        {
          'aa-sch-country_code': keywords.countryCode,
          'aa-sch-supply_type': keywords.supplyType,
          'aa-sch-publisher': keywords.publisherName,
          'aa-sch-page_type': keywords.pageType,
          'no-sno-publishergroup': keywords.publisherGroup,
          'no-sno-news-section': keywords.newsSection,
        },
        getAssetKeywords(asset, dynamicAR, playerAspectRatio),
        getGlimrKeywords(),
        getAppTypeKeyword(),
        ...(await Promise.all([
          getTcfConsentKeywords(),
          getAdConsentKeywords(consentManager),
        ]))
      )
    )
  );
};
